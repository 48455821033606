import { StorageKeys, ServiceOperations, logging } from 'utils';
import Lockr from 'lockr';
import { EnvHelper, GeneralHelper } from 'utils/helpers';
import { socketAuth } from 'services';

export class KeepAlive {
  private readonly logger = logging.getLogger('SocketAuth');

  private readonly interval: number = 10000;
  private readonly agentAliveInterval: number = 15000;
  private readonly intervalSetClientTrue: number = 30000; //30 seconds


  public invokeKeepAlive() {
    setInterval(() => this.signalAlive(), this.interval);
    setInterval(() => this.agentAlive(), this.agentAliveInterval);
  }

  public invokeSetHasClientTrue() {
    setInterval(() => this.setClientTrue(), this.intervalSetClientTrue);
  }

  private setClientTrue() {
    const userId = Lockr.get<string>(StorageKeys.UserId);

    if (userId) {
      socketAuth.setHasCC4TeamsTrue(userId);
    }
  }

  private signalAlive(): Promise<object> {
    return GeneralHelper.invokeServiceCall("cc4teams", ServiceOperations.KeepAlive, this.logger)
  }

  private checkInClient(sip: string): Promise<any> {
    return GeneralHelper.invokeServiceCall(sip, ServiceOperations.CheckInClient, this.logger)
  }

  private resetClientAvailability(sip: string): Promise<any> {
    return GeneralHelper.invokeServiceCall(sip, ServiceOperations.ResetClientAvailability, this.logger)
  }

  private logClientException(exception: string): Promise<any> {
    return GeneralHelper.invokeServiceCall(exception, ServiceOperations.LogClientException, this.logger)
  }

  public agentAlive() {
    const sip = Lockr.get<string>(StorageKeys.SIP);
    if (sip) {
      this.checkInClient(sip).catch(err => {
        if (err && err.message) {
          console.log(err.message);
        }
      });
    }
  }

  public resetAgentAvailability() {
    // REMOVE whole if when implemented in Stage3
    if (EnvHelper.isStage3()) {
      const userId: string = Lockr.get<string>(StorageKeys.UserObjectId) || "";
      const tenantId: string = Lockr.get<string>(StorageKeys.CompanyKey) || "";
      if (userId && tenantId) {
        socketAuth.setHasCC4TeamsFalse(userId, tenantId);
      }
    } else {
      const sip = Lockr.get<string>(StorageKeys.SIP);
      if (sip) {
        this.resetClientAvailability(sip).catch(err => {
          console.log(err.message);
        });
      }
    }
  }

  public sendExceptionLog() {
    const exception = Lockr.get<string>(StorageKeys.Exception);
    if (exception) {
      this.logClientException(exception);
      Lockr.rm(StorageKeys.Exception);
    }
  }
}

export const keepAlive = new KeepAlive();
